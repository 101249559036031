import {SearchRange} from "src/api";
import {useObserver} from "mobx-react";
import {Col, Input, Row} from "reactstrap";
import React from "react";

type RangeFilterProps = {
    placeholder: string,
    value: SearchRange,
    onChange: (value: SearchRange) => void
}

export const RangeFilter = (props: RangeFilterProps) => useObserver(() => {
    const toInput = (x: number | null) => x == null || isNaN(x) ? '' : x;
    const fromInput = (x: string) => !x || isNaN(parseInt(x)) ? null : parseInt(x);
    const active = props.value.from != null || props.value.to !== null;
    return (
        <Row>
            <Col xs={6} style={{ paddingRight: 0 }}>
                <Input value={toInput(props.value.from)}
                       onChange={x => props.onChange({
                           to: props.value.to,
                           from: fromInput(x.target.value)
                       })}
                       type={"number"}
                       placeholder={"From"}/>
            </Col>
            <Col xs={6}>
                <Input value={toInput(props.value.to)}
                       onChange={x => props.onChange({
                           to: fromInput(x.target.value),
                           from: props.value.from
                       })}
                       type={"number"}
                       placeholder={"To"}/>
            </Col>
            <Col xs={12}>
                Filter by {props.placeholder}{' '}
                {active && (
                    <span className={"badge badge-secondary"}
                          style={{ cursor: "pointer" }}
                          onClick={() => props.onChange({ from: null, to: null })}>
                        x
                    </span>
                )}
            </Col>
        </Row>
    )
});
