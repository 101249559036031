import {useRootStore} from "src/utils";
import {useObserver} from "mobx-react";
import React from "react";
import {PictureViewer} from "src/components/PictureViewer";
import {Card, CardBody, CardText, CardTitle, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {LoadingIf} from "src/components/Loading";
import {BlockExtraFileInfoDto, BlockExtraFileType, BlockInfoDto} from "src/api";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {formatSize} from "src/components/FlashSizeEditorStore";

const BlockInfo = (props: { info: BlockInfoDto }) => useObserver(() => {
    const b = props.info.block;
    const i = props.info;
    return <>
        Model: {b.blockModel}<br/>
        Id: {b.id}<br/>
        Car Brand: {i.carBrand}<br/>
        Manufacturer: {i.manufacturer}<br/>
        MCU: {i.cpu.name} (Flash: {formatSize(i.cpu.flashSize?.toString() ?? "0")})<br/>
        Additional Flash: {formatSize(b.additionalFlashSize?.toString() ?? "0")}<br/>
        FIDs: {i.fids.map(f=><span>{f}&nbsp;</span>)}<br/>
        Storage box id: {b.storageBoxIdentifier}<br/>
        Description:<br/>
        {b.description}
    </>;
});


const FileList = (props: { files: BlockExtraFileInfoDto[], type: BlockExtraFileType, title: string }) => {
    const files = props.files.filter(x => x.type == props.type);
    if (files.length == 0)
        return null;
    return <div>
        <h5>{props.title}</h5>
        <ListGroup>
            {files.map(f => <ListGroupItem>
                <a href={"/api/block-files/" + f.blockId + "/" + f.blobId} target="_blank">{f.name}</a>
            </ListGroupItem>)}
        </ListGroup>
    </div>

}

export const BlockPage = () => {
    const s = useRootStore().rootStore.blockPageStore;
    return useObserver(() => <div className={"container-xl"}>
        <LoadingIf isLoading={s.block == null}>
            <Card>
                <CardBody>
                    <CardTitle tag="h4">ECU {s.block?.block.blockModel} (#{s.block?.block.id})
                        &nbsp;
                        <RouterLink
                            className="btn btn-primary"
                            routeName={RouteNames.editBlock}
                            params={{"id": s.block?.block?.id?.toString() || ""}}>
                            Edit
                        </RouterLink>
                    </CardTitle>
                    <div>
                        <Row>
                            <Col xl={8} lg={9}>
                                <PictureViewer
                                    items={s.images.map(img => ({
                                        fileName: img.originalFileName,
                                        downloadUrl: "/api/block-files/" + img.blockId + "/" + img.blobId,
                                        imageUrl: img.isImage ? ("/api/block-files/" + img.blockId + "/" + img.blobId) : undefined
                                    }))}
                                    style={{
                                        width: '100%',
                                        height: '500px'
                                    }}/>
                            </Col>
                            <Col>
                                <BlockInfo info={s.block!}/>
                                <h4>Files</h4>
                                <FileList files={s.files} type={BlockExtraFileType.Firmware} title={"Firmware"}/>
                                <FileList files={s.files} type={BlockExtraFileType.Log} title={"Logs"}/>
                                {!!s.owner && (
                                    <div>
                                        <h5>Owner</h5>
                                        {s.owner?.name}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </LoadingIf>
    </div>
    );
};