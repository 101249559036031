import React, {FC} from "react";
import {useRootStore} from "src/utils";
import {Button, Col, Fade, Input, InputGroup, InputGroupAddon, ListGroupItem, Row} from "reactstrap";
import {GridView} from "src/components/GridView";
import {bind, toRussianDate} from "src/utils";
import {useObserver} from "mobx-react";
import {BlockOrder, BlockSearchResult, BlockType} from "src/api";
import {RouterLink} from "mobx-state-router";
import {RouteNames} from "src/routes";
import {StringFilter} from "src/components/StringFilter";
import {RangeFilter} from "src/components/RangeFilter";
import {StorePagination} from "src/components/StorePagination";
import {OrderSelect} from "src/components/OrderSelect";
import {SelectFilter} from "src/components/SelectFilter";
import {LatestUpdates} from "src/components/LatestUpdates";

const BlockView: FC<{
    block: BlockSearchResult
}> = ({ block }) => {
    return (
        <div>
            <ListGroupItem key={block.block.id} className="mb-3">
                <small className={"float-right text-muted text-uppercase text-right"}>
                    <div><b>Created</b> - {toRussianDate(block.block.createdAt, false)}</div>
                    {block.block.createdAt !== block.block.updatedAt && (
                        <div><b>Updated</b> - {toRussianDate(block.block.updatedAt, false)}</div>
                    )}
                    <div style={{fontSize: "18px"}}>
                        {block.block.isDead && !block.block.isUnknown ? (
                            <div className="h2 badge badge-danger text-uppercase">Dead</div>
                        ) : block.block.isUnknown ? (
                            <div className="h2 badge badge-warning text-uppercase">Unknown</div>
                        ) : null}
                    </div>
                </small>
                <b>{block.carBrand}</b>
                <RouterLink
                    routeName={RouteNames.block}
                    params={{"id": block.block.id.toString()}}
                >
                    <h5>{block.block.blockModel}</h5>
                </RouterLink>
                {block.manufacturer}
            </ListGroupItem>
        </div>
    )
}

const BlockList = (props: { items: BlockSearchResult[] }) => useObserver(() => {
    return GridView<BlockSearchResult>({ items: props.items, render: dto => <BlockView block={dto} /> });
});

export const BlockListPage: FC = () => {
    const root = useRootStore();
    const store = root.rootStore.blockListStore;
    return useObserver(() => <>
        <Row>
            <Col xs={9}>
                <InputGroup>
                    <InputGroupAddon addonType={"append"}>
                        <Button color={"secondary"}
                                style={{ width: "100%" }}
                                onClick={() => store.toggleFilters()}>
                            🔍 Filters
                        </Button>
                    </InputGroupAddon>
                    <Input {...bind(store, "search")} placeholder={"Search..."}/>
                </InputGroup>
            </Col>
            <Col xs={3}>
                <RouterLink className="btn btn-primary d-block"
                            routeName={RouteNames.newBlock}>
                    + New ECU
                </RouterLink>
            </Col>
        </Row>
        {store.filters && (
            <Fade>
                <Row className={"mt-2"}>
                    <Col xs={2}>
                        <div className={"font-weight-bold"}>Order By</div>
                        <OrderSelect
                            options={store.orderOptions}
                            value={store.orderByField}
                            setValue={(field) => store.orderByField = field as BlockOrder}
                            desc={store.orderByDescending}
                            setDesc={desc => store.orderByDescending = desc}
                        />
                    </Col>
                    <Col xs={10}>
                        <div className={"font-weight-bold"}>Filters</div>
                        <Row>
                            <Col xs={2}>
                                <RangeFilter placeholder={"Id"}
                                             value={store.id}
                                             onChange={flash => store.id = flash}/>
                            </Col>
                            <Col xs={2}>
                                <RangeFilter placeholder={"BitBox FID"}
                                             value={store.bitBoxFid}
                                             onChange={flash => store.bitBoxFid = flash}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"Description"}
                                              value={store.description}
                                              onChange={val => store.description = val}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"Model"}
                                              value={store.model}
                                              onChange={val => store.model = val}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"Car Brand"}
                                              value={store.carBrandName}
                                              onChange={flash => store.carBrandName = flash}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"MCU"}
                                              value={store.cpuName}
                                              onChange={flash => store.cpuName = flash}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"Manufacturer"}
                                              value={store.manufacturerName}
                                              onChange={flash => store.manufacturerName = flash}/>
                            </Col>
                            <Col xs={2}>
                                <StringFilter placeholder={"Storage Box ID"}
                                              value={store.storageBoxIdentifier}
                                              onChange={flash => store.storageBoxIdentifier = flash}/>
                            </Col>
                            <Col xs={2}>
                                <SelectFilter placeholder={"ECU Type"}
                                              options={store.blockTypeOptions}
                                              value={store.blockType}
                                              defaultValue={BlockType.Any}
                                              onChange={type => store.blockType = type as BlockType}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fade>
        )}
        <LatestUpdates
            content={<BlockList items={store.latestUpdates}/>}
            isOpen={store.latestUpdatesOpen}
            setIsOpen={(val) => store.latestUpdatesOpen = val}
        />
        <BlockList items={store.items}/>
        <StorePagination store={store} />
    </>);
}