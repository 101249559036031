import {useObserver} from "mobx-react";
import {Input} from "reactstrap";
import React from "react";

type StringFilterProps = {
    placeholder: string,
    value: string,
    onChange: (value: string) => void
};

export const StringFilter = (props: StringFilterProps) => useObserver(() => (
    <div>
        <Input value={props.value}
               onChange={x => props.onChange(x.target.value)}
               placeholder={props.placeholder}/>
        Filter by {props.placeholder}{' '}
        {!!props.value && (
            <span className={"badge badge-secondary"}
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onChange('')}>
                x
            </span>
        )}
    </div>
));
