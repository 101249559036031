import React, {CSSProperties} from "react";
import ReactDOM from "react-dom";
import {Button} from "reactstrap";

export interface PictureViewerItem {
    fileName: string,
    downloadUrl: string,
    imageUrl?: string
}

const Item = (props: { item: PictureViewerItem }) =>
    props.item.imageUrl ?
        <img src={props.item.imageUrl}
             style={{width: '100%', height: '100%', objectFit: "contain"}}
        />
        : <div style={{textAlign: "center", verticalAlign: 'center', margin: 'auto'}}>
            <a href={props.item.downloadUrl}>{props.item.fileName}</a>
        </div>;

const CarouselItem = (props: { item: PictureViewerItem, onLeft: () => void, onRight: () => void }) => {
    return <div style={{width: '100%', height: '100%', position: 'relative'}}>
        <Item item={props.item}/>
        <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        }}>
            <table style={{height: '100%'}}>
                <tbody>
                <tr>
                    <td valign="middle"><Button onClick={(e) => {
                        props.onLeft();
                        e.preventDefault();
                        e.stopPropagation();
                    }}>&lt;</Button></td>
                    <td width="100%"/>
                    <td valign="middle"><Button onClick={(e) => {
                        props.onRight();
                        e.preventDefault();
                        e.stopPropagation();
                    }}>&gt;</Button></td>
                </tr>
                <tr style={{height: "10px"}}>
                    <td/>
                    <td/>
                    <td align="right">
                        <a href={props.item.downloadUrl}
                           target="_blank"
                           className="btn btn-primary"
                           onClick={(e) => {
                               e.stopPropagation();
                           }}
                        >↓</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
};

const ItemSelect = (props: { item: PictureViewerItem, active: boolean, onClick: () => void }) => <div
    style={{
        display: "inline-block",
        width: 50,
        height: 50,
        cursor: 'pointer',
        margin: 10
    }}
    className={"rounded border " + (props.active ? "border-primary" : "")}
    onClick={() => props.onClick()}
>
    {props.item.imageUrl ?
        <img src={props.item.imageUrl}
             style={{width: '100%', height: '100%', objectFit: "contain"}}
        />
        : <div style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }}>
            {props.item.fileName}
        </div>
    }
</div>


class FullscreenModal extends React.Component<any, any> {
    private modal: HTMLDivElement;

    constructor(props: any, context: any) {
        super(props, context);
        this.modal = document.createElement("div");
        this.modal.style.position = "fixed";
        this.modal.style.left = "0px";
        this.modal.style.top = "0px";
        this.modal.style.width = this.modal.style.height = "100%";
    }

    componentDidMount() {
        document.body.appendChild(this.modal);
    }

    componentWillUnmount() {
        document.body.removeChild(this.modal);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.modal);
    }
}

interface PictureViewerCoreProps {
    items: PictureViewerItem[];
    selectedIndex: number;
    selectedIndexChanged: (idx: number) => void;
    style: CSSProperties,
    imageClicked?: () => void
}

function coalesceIndex(idx: number, len: number): number {
    return Math.max(0, (idx + len) % len);
}

export class PictureViewerCore extends React.Component<PictureViewerCoreProps, any> {
    constructor(props: any, context: any) {
        super(props, context);
    }

    render() {
        if (this.props.items.length == 0)
            return <div
                className={"border rounded"}
                style={{textAlign: "center", ...this.props.style}}>No images</div>;
        const selectedIndex = coalesceIndex(this.props.selectedIndex, this.props.items.length);
        const changeIndex = (idx: number) => this.props.selectedIndexChanged(coalesceIndex(idx, this.props.items.length));
        return <div style={{
            ...this.props.style
        }}>
            <div
                className={"border rounded"}
                style={{
                    textAlign: "center", width: '100%', height: 'calc(100% - 70px)',
                    cursor: this.props.imageClicked ? 'pointer' : 'inherit'
                }}
                onClick={() => {
                    if (this.props.imageClicked)
                        this.props.imageClicked();
                }}

            >
                <CarouselItem
                    onRight={() => changeIndex(selectedIndex + 1)}
                    onLeft={() => changeIndex(selectedIndex - 1)}
                    item={this.props.items[selectedIndex]}/>
            </div>
            <div style={{
                marginTop: 10,
                textAlign: "center",
                height: 70
            }}>
                {this.props.items.map((item, idx) => <ItemSelect
                    onClick={() => changeIndex(idx)}
                    item={item}
                    active={idx == selectedIndex}/>)}
            </div>
        </div>
    }
}

interface PictureViewerProps {
    items: PictureViewerItem[];
    style: CSSProperties
}

interface PictureViewerState {
    selectedIndex: number,
    fullscreen: boolean
}

export class PictureViewer extends React.Component<PictureViewerProps, PictureViewerState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.escape = this.escape.bind(this);
        this.state = {
            selectedIndex: 0,
            fullscreen: false
        }
    }
    
    escape(event: KeyboardEvent){
        if (event.key === "Escape") {
            this.setState({fullscreen: false});
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escape, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escape, false);
    }

    render() {
        const createCore = (style: CSSProperties) => <PictureViewerCore
            items={this.props.items}
            selectedIndex={this.state.selectedIndex}
            selectedIndexChanged={idx => this.setState({selectedIndex: idx})}
            style={style}
            imageClicked={() => this.setState({fullscreen: !this.state.fullscreen})}
        />;
        return <>
            {createCore(this.props.style)}
            {this.state.fullscreen
                ? <FullscreenModal>
                    <div style={{
                        position: 'relative', width: '100%', height: '100%',
                        background: 'rgba(255,255,255,0.8)',
                        backdropFilter: 'blur(3px)'
                    }}>
                        {createCore({width: '100%', height: '100%'})}
                    </div>
                </FullscreenModal>
                : null}
        </>;
    }
}

