import {action, computed, observable} from "mobx";
import {Block} from "src/api";
import {NamedEntitySelectStore, NamedEntityCrudStore} from "src/stores/components/NamedEntityListStore";
import {api} from "src/globals";
import {emptyIfNull, isNullOrWhitespace} from "src/utils";
import {FlashSizeEditorStore} from "src/components/FlashSizeEditorStore";

export class FidItemStore {
    @observable options: {name: string, value: string}[] = [];
    @observable id?: string;

    constructor(id?: string) {
        this.id = id;
        this.getOptions('');
    }

    async getOptions(query: string) {
        const response = await api.familyId.search(query, 0, 20);
        const items = response.items.map(item => ({
            name: `${item.ecuType} (#${item.id})`,
            value: `${item.id}`,
        }));
        if (this.id) {
            const id = Number(this.id);
            const item = await api.familyId.get(id);
            items.push(Boolean(item) ? {
                name: `${item.ecuType} (#${item.id})`,
                value: this.id,
            } : {
                name: `Unknown (${this.id})`,
                value: this.id,
            });
        }
        const set = new Set();
        this.options = items.filter(item => {
            if (set.has(item.value))
                return false;
            set.add(item.value);
            return true;
        });
        return this.options;
    }
}

export class FidListStore {
    @observable items: FidItemStore[];

    constructor(fids: number[]) {
        this.items = fids.map(fid => new FidItemStore(fid.toString()));
    }

    @action addEmpty = () => this.items = this.items.concat(new FidItemStore());
    @action delete = (index: number) => this.items = this.items.filter((_, i) => i != index);
}

export class BlockEditorStore {
    @observable id : number | null;
    @observable blockModel : string;
    @observable carBrandId : NamedEntityCrudStore;
    @observable manufacturerId : NamedEntityCrudStore;
    @observable cpuId : NamedEntityCrudStore;
    @observable hasAdditionalFlash : boolean;
    @observable flashSize : FlashSizeEditorStore;
    @observable bitBoxFids : FidListStore;
    @observable storageBoxIdentifier : string;
    @observable description : string;
    @observable createdAt : string;
    @observable updatedAt : string;
    @observable isDead : boolean;
    @observable isUnknown : boolean;
    @observable ownerId : NamedEntitySelectStore;

    constructor(block : Block | null, fids: number[]) {
        this.bitBoxFids = new FidListStore(fids);
        if (block) {
            this.id = block.id;
            this.createdAt = block.createdAt;
            this.updatedAt = block.updatedAt;
            this.blockModel = block.blockModel;
            this.carBrandId = new NamedEntityCrudStore(api.carBrand, block.carBrandId);
            this.manufacturerId = new NamedEntityCrudStore(api.blockManufacturer, block.manufacturerId);
            this.cpuId = new NamedEntityCrudStore(api.blockCpu, block.cpuId);
            this.flashSize = new FlashSizeEditorStore(block.additionalFlashSize ?? 0);
            this.storageBoxIdentifier = block.storageBoxIdentifier;
            this.description = block.description;
            this.hasAdditionalFlash = block.additionalFlashSize != null;
            this.isDead = block.isDead && !block.isUnknown;
            this.isUnknown = block.isUnknown;
            this.ownerId = new NamedEntitySelectStore({ getAll: () => api.block.searchPossibleOwners() }, block.ownerId);
        } else {
            this.id = null;
            this.createdAt = "";
            this.updatedAt = "";
            this.blockModel = "";
            this.carBrandId = new NamedEntityCrudStore(api.carBrand, null);
            this.manufacturerId = new NamedEntityCrudStore(api.blockManufacturer, null);
            this.cpuId = new NamedEntityCrudStore(api.blockCpu, null);
            this.flashSize = new FlashSizeEditorStore(0);
            this.storageBoxIdentifier = "";
            this.description = "";
            this.bitBoxFids.addEmpty();
            this.hasAdditionalFlash = false;
            this.isDead = false;
            this.isUnknown = false;
            this.ownerId = new NamedEntitySelectStore({ getAll: () => api.block.searchPossibleOwners() }, null);
        }
    }

    @computed get isFidConfigured() {
        const fids = this.bitBoxFids.items.map(item => Number(item.id));
        const invalid = fids.filter(fid => fid < 0 || isNaN(fid));
        if (invalid.length > 0)
            return false;
        const set = new Set(fids);
        if (set.size !== fids.length)
            return false;
        return true;
    }
    
    @computed get value() : {block: Block, fids: number[]} | null
    {
        if(isNullOrWhitespace(this.blockModel)
            || this.cpuId.selectedId == null
            || this.carBrandId.selectedId == null
            || this.manufacturerId.selectedId == null)
            return null;

        const flashSize = this.flashSize.value;
        if (this.hasAdditionalFlash && isNaN(flashSize))
            return null;
        if (!this.isFidConfigured)
            return null;

        return {
            fids: this.bitBoxFids.items.map(item => Number(item.id)),
            block: {
                id: this.id || 0,
                blockModel: this.blockModel,
                description: emptyIfNull(this.description),
                storageBoxIdentifier: emptyIfNull(this.storageBoxIdentifier),
                additionalFlashSize: this.hasAdditionalFlash ? flashSize : null,
                isDeleted: false,
                cpuId: this.cpuId.selectedId,
                carBrandId: this.carBrandId.selectedId,
                manufacturerId: this.manufacturerId.selectedId,
                createdAt: this.createdAt,
                updatedAt: this.updatedAt,
                isDead: this.isDead,
                isUnknown: this.isUnknown,
                flashSize: 0,
                ownerId: this.ownerId.selectedId,
            },
        };
    }
}