import {action, computed, observable} from "mobx";
import {BlockEditorStore} from "src/stores/components/BlockEditorStore";
import {RootStore} from "src/stores/RootStore";
import {RequestTracking} from "src/utils/RequestTracking";
import {api} from "src/globals";
import {RouteNames} from "src/routes";
import {createBlob, isNullOrWhitespace, openFile} from "src/utils";
import {BlockExtraFile, BlockExtraFileType} from "src/api";

export class BlockEditorPageFileStore {
    @observable file: BlockExtraFile;
    @observable editName: string;
    @observable editType: BlockExtraFileType;
    @observable isEditing = false;

    constructor(file: BlockExtraFile) {
        this.file = file;
        this.editName = file.name;
        this.editType = file.type;
    }

    @action edit() {
        this.editName = this.file.name;
        this.editType = this.file.type;
        this.isEditing = true;
    }

    @action cancelEdit() {
        this.isEditing = false;
    }

    async confirmEdit() {
        const file: BlockExtraFile = {
            blobId: this.file.blobId,
            blockId: this.file.blockId,
            type: this.editType,
            name: this.editName,
            originalFileName: this.file.originalFileName
        };
        await api.block.updateFile(file);
        this.file = file;
        this.isEditing = false;
    }
}

export class BlockEditorPageStore extends RequestTracking {
    constructor(public rootStore: RootStore) {
        super();
    }

    @observable block: BlockEditorStore | null = null;
    @observable carModels: string[] = [];
    @observable files: BlockEditorPageFileStore[] = [];
    @observable uploadCount = 0;

    @computed get fileIsUploading() {
        return this.uploadCount != 0;
    }

    @computed get canSave() {
        return !this.isLoading && this.block && this.block.value != null && this.block.isFidConfigured;
    }

    async load(id: number): Promise<void> {
        this.block = null;
        this.block = new BlockEditorStore(await api.block.getById(id), await api.block.getBitBoxFids(id));
        this.carModels = await api.block.getCarModels(id);
        this.files = (await api.block.getFiles(id)).map(f => new BlockEditorPageFileStore(f));
    }

    async save() {
        if (this.block == null || this.block.value == null || !this.canSave)
            return;
        const { block: b, fids } = this.block.value;
        await this.track(() => api.block.update(b.id, b.blockModel,
          b.carBrandId, b.manufacturerId, b.cpuId, b.additionalFlashSize,
          fids, b.storageBoxIdentifier, b.description, this.carModels, b.isDead, b.isUnknown, b.ownerId));
        await this.load(b.id);
    }
    
    async delete() {
        if(this.block == null)
            return;
        if(!confirm("Delete?"))
            return;
        await this.track(()=>api.block.delete(this.block!.id!));
        this.rootStore.routerStore.goTo(RouteNames.home);
    }

    removeCar(car: string) {
        this.carModels = this.carModels.filter(c => c != car);
    }

    addNewCar() {
        const name = prompt("Enter name:");
        if (name == null || isNullOrWhitespace(name))
            return;
        this.removeCar(name);
        this.carModels.push(name);
    }

    async uploadNewFile() {
        const blob = await openFile();
        await this.uploadFile(blob);
    }

    async uploadFiles(files: FileList) {
        for (let c = 0; c < files.length; c++) {
            await this.uploadFile(files[c]);
        }
    }

    async uploadFile(file: File) {
        if (this.block == null || this.block.id == null)
            return;

        this.uploadCount++;
        try {
            const blobId = await createBlob(file);
            if (this.block == null || this.block.id == null)
                return;
            const res = await api.block.attachFile(this.block.id!, blobId, file.name);
            this.files.push(new BlockEditorPageFileStore(res));
        } finally {
            this.uploadCount--;
        }
    }

    async removeFile(file: BlockEditorPageFileStore) {
        if (confirm("Are you sure? This action cannot be undone.")) {
            await api.block.removeFile(file.file.blockId, file.file.blobId);
            this.files = this.files.filter(f => f.file.blobId != file.file.blobId || f.file.blockId != file.file.blockId);
        }

    }
}