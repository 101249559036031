import {computed, observable} from "mobx";
import {Loadable} from "src/stores/table/LoadableStore";
import {INamedEntity, INamedEntityRpc, INamedEntitySelectRpc} from "src/stores/interfaces/INamedEntityRpc";

export class NamedEntitySelectStore extends Loadable {
    @observable items? : INamedEntity[];
    @observable selectedId : number | null;

    constructor(protected rpc: INamedEntitySelectRpc, selectedId: number | null) {
        super();
        this.selectedId = selectedId;
    }

    @computed get selection() {
        return this.items?.find(x => x.id === this.selectedId);
    }

    async load(): Promise<void> {
        this.items = await this.rpc.getAll();
    }
}

export class NamedEntityCrudStore extends NamedEntitySelectStore {
    constructor(rpc: INamedEntityRpc, selectedId: number | null) {
        super(rpc, selectedId);
    }
    
    async selectNew() : Promise<void> {
        const id = await this.askToCreateNew();
        if (id != null)
            this.selectedId = id;
    }

    async askToCreateNew() : Promise<number | null> {
        const name = prompt("Enter name", "name");
        if(name == null)
            return null;
        const rpc = this.rpc as INamedEntityRpc;
        const id = await rpc.create(name);
        this.items?.push({
            id: id,
            name: name
        });
        return id;
    }
}