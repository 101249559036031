import {FormGroup} from "reactstrap";
import SelectSearch from "react-select-search";
import React from "react";

export function SelectFilter(props: {
    placeholder: string,
    options: string[],
    defaultValue: string,
    value: string,
    onChange: (value: string) => void
}) {
    const items = props.options
        .filter(o => o !== props.defaultValue)
        .map(o => ({ name: o.split(/(?=[A-Z])/).join(' '), value: o }));
    const active = props.value !== props.defaultValue;
    return (
        <FormGroup>
            <SelectSearch
                search
                placeholder={props.placeholder}
                value={props.value === props.defaultValue ? '' : props.value}
                options={items}
                onChange={selected => props.onChange(selected as any as string)}
            />
            Filter by {props.placeholder}{' '}
            {active && (
                <span className={"badge badge-secondary"}
                      style={{ cursor: "pointer" }}
                      onClick={() => props.onChange(props.defaultValue)}>
                        x
                    </span>
            )}
        </FormGroup>
    )
};
